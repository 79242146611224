import React from 'react';

import Container from '@mui/material/Container';
import { Box } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import HoverImage from './HoverImage';
import { TouchApp } from '@mui/icons-material';
import Typography from '@mui/material/Typography'; 
  
function ArrowImage(props) {
    return (
        <HoverImage {...props} style={{padding: 2, objectFit: 'contain', ...props.style}}
            srcTop={`./assets/object_moving/original/${props.filename}`} 
            srcHover={ `./assets/object_moving/arrow/${props.filename}`}
            srcClick={`./assets/object_moving/result/${props.filename}`} />
    );
  }

function ObjectMoving () {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    // if (opacity < 0.2)
    //     opacity = 0;
    return (
        <Container maxWidth="lg" sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center'
            }} >   
          
          
          <Box style={{paddingBottom: 32}}>
            {
              isSmall ? (
                <Grid container spacing={1}>
                <Grid item xs={6}>
                  <ArrowImage filename='img1.jpg'/>
                </Grid>
                <Grid item xs={6}>
                  <ArrowImage filename='img6.jpg'/>
                </Grid>
                <Grid item xs={12}>
                  <ArrowImage filename='img4.jpg'/>
                </Grid>
                <Grid item xs={12}>
                  <ArrowImage filename='img5.jpg'/>
                </Grid>
                <Grid item xs={12}>
                  <ArrowImage filename='img2.jpg'/>
                </Grid>
                <Grid item xs={12}>
                  <ArrowImage filename='img3.jpg'/>
                </Grid>
              </Grid>
              ) :
              (
                <table style={{borderCollapse:'collapse', borderSpacing:4}}>
                <tbody style={{padding:0}}>
                  <tr style={{padding:0}}>
                    <td style={{width: 625, padding:0}}>
                      <ArrowImage filename='img1.jpg' width='50%' style={{paddingRight:8}} />
                      <ArrowImage filename='img6.jpg' width='50%' style={{paddingRight:8}}/>
                    </td>
                    <td style={{width: 282, padding:0}}>
                      <ArrowImage filename='img4.jpg' width='100%' height='50%'/>
                      <ArrowImage filename='img5.jpg' width='100%' height='50%'/>
                    </td>
                  </tr>
                  <tr style={{padding:0}}>
                    <td colSpan="2" style={{padding:0}}>
                      <ArrowImage filename='img2.jpg' width='50%' height='100%' style={{paddingRight:8}}/>
                    <ArrowImage filename='img3.jpg' width='50%' height='100%' />
                    </td>
                  </tr> 
                </tbody>
                </table>
              )
            }
            <Typography>
            <TouchApp /> Click on any image to see results
          </Typography>
          </Box>
          </Container>
    );
};
export default ObjectMoving;