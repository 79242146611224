import React, { useState } from 'react';

function HoverImage (props) {
    const imgStyle = {
        maxWidth: '100%',
        maxWeight: '100%',
        objectFit: 'contain',
        ...props.style
    };

    const srcHover = props.srcHover === "" ? props.srcTop : props.srcHover;

    const [active, setActive] = useState(0);

    
    let opTop = 1, opHover = 0, opClick = 0;
    if (active == 1) {
        opTop = 0; opHover = 1; opClick = 0;
    }
    else if(active == 2) {
        opTop = 0; opHover = 0; opClick = 1;
    }

    let boxStyle = {}
    if ("width" in props)
        boxStyle.width = props.width
    if ("height" in props)
        boxStyle.height = props.height
    
    return (
    <div style={{position: 'relative', float:'left', cursor:'pointer', ...boxStyle}} >
        <img src={props.srcTop} style={{position: 'absolute', opacity:opTop, ...imgStyle}}
            onMouseOver={e => {setActive(1)}}
            onMouseOut={e => {setActive(0)}}
            onMouseDown={e => {setActive(2)}}
            onMouseUp={e => {setActive(0)}}
            onTouchEnd={e => {e.stopPropagation(); e.preventDefault(); setActive(active > 0 ? 0 : 2)}}  
        />
        <img src={srcHover} style={{position: 'absolute', opacity:opHover, ...imgStyle}}
            onMouseOver={e => {setActive(1)}}
            onMouseOut={e => {setActive(0)}}
            onMouseDown={e => {setActive(2)}}
            onMouseUp={e => {setActive(0)}}
            onTouchEnd={e => {e.stopPropagation(); e.preventDefault(); setActive(active > 0 ? 0 : 2)}}     
        />
        <img src={props.srcClick} style={{position: 'relative', opacity:opClick, ...imgStyle}}
            onMouseOver={e => {setActive(1)}}
            onMouseOut={e => {setActive(0)}}
            onMouseDown={e => {setActive(2)}}
            onMouseUp={e => {setActive(0)}}
            onTouchEnd={e => {e.stopPropagation(); e.preventDefault(); setActive(active > 0 ? 0 : 2)}}  
        />
    </div>
    );
};
export default HoverImage;