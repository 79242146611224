import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { blueGrey } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ImageListItem from '@mui/material/ImageListItem';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import Card from '@mui/material/Card';

import ImageGalleryList from './components/ImageGalleryList';
import Abstract from './components/Abstract';
import Approach from './components/Approach';
import ObjectMoving from './components/ObjectMoving';
import ImageAttributions from './components/ImageAttributions';
import HoverImage from './components/HoverImage';
import ObjectInsert from './components/ObjectInsert';
import ClickAlert from './components/ClickAlert';

const images = [
  'img1.jpg',
  'img2.jpg',
  'img5.jpg',
  'img7.jpg',
  'img8.jpg',
  'img4.jpg',
  'img6.jpg',
  'img3.jpg',
]

const font_theme = responsiveFontSizes(createTheme({
  typography: {
    allVariants: {
      fontFamily: [
        'Google Sans',
        'sans-serif',
        'Roboto',
      ].join(','),
    }

  }
}));


// const mainTheme = {
//   headerColor: '#F3F6F9',
//   headerButtonColor: 'black_button',
//   headerTextColor: null,
//   firstColor: '#FFFFFF',
//   secondColor: '#F3F6F9'
// }

const mainTheme = {
  headerColor: blueGrey.A700,
  headerButtonColor: 'white_button',
  headerTextColor: 'white',
  firstColor: '#F3F6F9',
  secondColor: '#FFFFFF',
}

export default function App() {
  const [open, setOpen] = React.useState(false);

  const queryTheme = useTheme();
  const isSmall = useMediaQuery(queryTheme.breakpoints.down('sm'));

  const handleClickOpenAtt = () => {
    setOpen(true);
  };

  const handleCloseAtt = (value) => {
    setOpen(false);
  };

  const nameSpacing = isSmall ? "   " : "        "

  return (
    <React.Fragment>

      <div style={{
        width: '100%',
        zIndex: 1,
        position: null,
      }} >

        <Box style={{
          backgroundColor: mainTheme.headerColor,
          display: 'flex',
          flexDirection: 'column',
          color: mainTheme.headerTextColor,
          textAlign: 'center',
          alignItems: 'center',
          width: '100%',
        }}
        >

          <Container maxWidth="lg">
            <ThemeProvider theme={font_theme}>
              <Typography variant={isSmall ? "h4" : "h3"} sx={{ my: 4, mt: 8, /*color: '#363636',*/ fontWeight: 500, lineHeight: 1.125 }}>
                ObjectDrop: Bootstrapping Counterfactuals for Photorealistic Object Removal and Insertion
              </Typography>
            </ThemeProvider>

            <Box style={{ marginBottom: 24, width: '100%' }} >
              <ThemeProvider theme={font_theme}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  <p style={{ whiteSpace: 'pre-wrap', lineHeight: 2, fontWeight: 400, fontSize: isSmall ? 14 : null }}>Daniel&nbsp;Winter<sup>1,2</sup>{nameSpacing}Matan&nbsp;Cohen<sup>1</sup>{nameSpacing}Shlomi&nbsp;Fruchter<sup>1</sup>{nameSpacing}Yael&nbsp;Pritch<sup>1</sup>{nameSpacing}Alex&nbsp;Rav&#8209;Acha<sup>1</sup>{nameSpacing}Yedid&nbsp;Hoshen<sup>1,2</sup></p>
                  <p style={{ whiteSpace: 'pre-wrap', lineHeight: 2, fontWeight: isSmall ? 400 : 600 }}><sup>1</sup>Google&nbsp;Research{isSmall ? <br /> : "          "}<sup>2</sup>The&nbsp;Hebrew&nbsp;University of&nbsp;Jerusalem</p>
                  <p style={{ whiteSpace: 'pre-wrap', lineHeight: 2, fontWeight: isSmall ? 400 : 600 }}>ECCV 2024</p>
                </Typography>
              </ThemeProvider>
              <Container sx={{ '& a': { m: 1 } }}>
                <Button href="https://arxiv.org/pdf/2403.18818.pdf" variant="outlined" size="large" color={mainTheme.headerButtonColor} sx={{ width: 120 }}> PDF </Button>
                <Button href="https://arxiv.org/abs/2403.18818" variant="outlined" size="large" color={mainTheme.headerButtonColor} sx={{ width: 120 }}> Arxiv </Button>
                <Button  style={{textTransform: 'none'}} href="https://object-mate.com" variant="outlined" size="large" color={mainTheme.headerButtonColor} sx={{ width: 180 }}> New: ObjectMate </Button>
              </Container>
            </Box>

          </Container>
          <Container maxWidth="md" style={{ marginBottom: 24 }}>
            <video poster="" autoPlay controls muted loop width="100%" style={{ borderRadius: '12px' }}>
              <source src="assets/teaser.mp4" type="video/mp4" />
            </video>
          </Container>
        </Box>


        <Box sx={{
          backgroundColor: mainTheme.firstColor,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
        >
          <Abstract />
        </Box>


        <Box sx={{
          backgroundColor: mainTheme.secondColor,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center'
        }}
        >
          <Container maxWidth="lg" sx={{ margin: 4 }} style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <Container maxWidth="md" sx={{ margin: 4 }} >
              <Typography variant="h6" component="h2" sx={{ fontWeight: 600 }}>
                OBJECT REMOVAL
              </Typography>
              <Typography>
                Our object removal model effectively eliminates objects and their effects on the scene from images.
                Despite being trained on a relatively small counterfactual dataset captured in controlled environments,
                the model demonstrates remarkable generalization to diverse scenarios, seamlessly removing large objects.
              </Typography>
              <ClickAlert />
            </Container>
            <ImageGalleryList>
              {images.map((item) => (
                <ImageListItem key={item}>
                  <HoverImage srcTop={`./assets/removal_images/inputs/${item}`}
                    srcHover={`./assets/removal_images/masks/${item}`}
                    srcClick={`./assets/removal_images/results/${item}`} />
                </ImageListItem>
              ))}
            </ImageGalleryList>
          </Container>
        </Box>

        <Box sx={{
          backgroundColor: mainTheme.firstColor,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
        >
          <Approach />
        </Box>

        <Box sx={{
          backgroundColor: mainTheme.secondColor,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center'
        }}
        >
          <ObjectInsert />
        </Box>

        <Box sx={{
          backgroundColor: mainTheme.firstColor,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center'
        }}
        >
          <Container maxWidth="lg" sx={{ margin: 4 }} >
            <Typography variant="h6" component="h2" sx={{ fontWeight: 600 }}>
              OBJECT MOVING
            </Typography>
            <Typography>
              Utilizing both our object removal and insertion models, we can seamlessly
              move objects within an image. This involves removing them from their original
              position and re-inserting them elsewhere, resulting in realistic transformations.
            </Typography>
          </Container>
          <ObjectMoving />
        </Box>


        <Box  maxWidth="100%" sx={{
          backgroundColor: mainTheme.secondColor,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          
        }}
        >
          <Container maxWidth="md" sx={{
            margin: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }} >
            <Stack maxWidth="100%" direction={{ xs: 'column', md: 'row' }} spacing={4}>
              <Stack>
                <Typography variant="h6" component="h2" sx={{ fontWeight: 600, textAlign: { xs: 'left', md: 'right' } }}>
                  BibTex
                </Typography>
              </Stack>
              <Stack maxWidth="100%" >
                <Container sx={{ 
                  maxWidth: '100%',
                  padding: 2,
                  backgroundColor: '#f5f5f5',
                  fontFamily: 'monospace',
                  overflowX:'scroll',
                  whiteSpace:'pre',
                  overflowX: 'auto',
                  overflowY: 'hidden',
                  }} >
                {String.raw`@misc{winter2024objectdrop,
      title={ObjectDrop: Bootstrapping Counterfactuals for Photorealistic Object Removal and Insertion},
      author={Daniel Winter and Matan Cohen and Shlomi Fruchter and Yael Pritch and Alex Rav-Acha and Yedid Hoshen},
      year={2024},
      eprint={2403.18818},
      archivePrefix={arXiv},
      primaryClass={cs.CV}
}`}
                </Container>
              </Stack>
            </Stack>
          </Container>
        </Box>

        <Box sx={{
          backgroundColor: blueGrey.A100,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
        >
          <Container maxWidth="md" sx={{ margin: 4 }} >
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={4}>
              <Stack>
                <Typography variant="h6" component="h2" sx={{ fontWeight: 600, textAlign: { xs: 'left', md: 'right' } }}>
                  ACKNOWLEDGMENT
                </Typography>
              </Stack>
              <Stack>
                <Typography variant="body1" component="span" sx={{ 'textAlign': 'left' }}>
                  We would like to thank to Gitartha Goswami, Soumyadip Ghosh, Reggie Ballesteros, Srimon Chatterjee, Michael Milne and James Adamson for providing the photographs that made this project possible. We thank Yaron Brodsky, Dana Berman, Amir Hertz, Moab Arar, and Oren Katzir for their invaluable feedback and discussions. We also appreciate the insights provided by Dani Lischinski and Daniel Cohen-Or, which helped improve this work.

                  <p>We thank owners of images on this site (<Link onClick={handleClickOpenAtt}>link</Link> for attributions) for sharing their valuable assets.
                  </p>
                </Typography>
                <ImageAttributions open={open} onClose={handleCloseAtt} />
              </Stack>
            </Stack>
          </Container>
        </Box>

      </div>
    </React.Fragment>
  );
}
