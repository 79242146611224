import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent'; 
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

const allImage = [
    {  src: "./assets/attributes/a_img1.jpg",
       url: "https://unsplash.com/photos/three-sail-boats-on-water-during-daytime-58AiTToabyE" 
    },
    {  src: "./assets/removal_images/inputs/img1.jpg",
       url: "https://unsplash.com/photos/a-green-house-sitting-on-top-of-a-body-of-water-8vxJ_5aHfGs" 
    },
    {  src: "./assets/removal_images/inputs/img2.jpg",
       url: "https://unsplash.com/photos/yellow-red-and-blue-hot-air-balloon-near-trees-and-body-of-water-under-blue-sky-during-daytime-a8YV2C3yBMk" 
    },
    {  src: "./assets/removal_images/inputs/img3.jpg",
       url: "https://unsplash.com/photos/green-leaf-tree-on-shore-XBxQZLNBM0Q" 
    },
    {  src: "./assets/removal_images/inputs/img4.jpg",
       url: "https://unsplash.com/photos/clear-drinking-glass-with-yellow-liquid-UyF3Tal_KXw" 
    },
    {  src: "./assets/removal_images/inputs/img5.jpg",
       url: "https://unsplash.com/photos/helicopter-landing-on-snow-mountain-in-tasman-glacier-in-mt-cook-new-zealand-the-helicopter-service-in-mt-cook-offers-scenic-flights-glacier-landing-and-emergency-rescue-Q0ustgD8caM" 
    },
    {  src: "./assets/removal_images/inputs/img6.jpg",
       url: "https://unsplash.com/photos/brown-giraffe-standing-on-brown-dirt-during-daytime-4F-uidmvKIA" 
    },
    {  src: "./assets/removal_images/inputs/img7.jpg",
       url: "https://unsplash.com/photos/brown-and-black-tiger-near-body-of-water-UIhc7sohnvc" 
    },
    {  src: "./assets/removal_images/inputs/img8.jpg",
       url: "https://unsplash.com/photos/a-dog-wearing-a-shirt-and-hat-on-a-beach-NtdMcMPlaA0"
    },
    {  src: "./assets/attributes/a_img2.jpg",
       url: "https://unsplash.com/photos/brown-wooden-framed-analog-mantle-clock-near-gray-petaled-flower-centerpiece-i8-e3LG0Z70" 
    },
    {  src: "./assets/attributes/a_img3.jpg",
       url: "https://unsplash.com/photos/shallow-focus-photography-of-potted-plants-UcfKYTan-LU" 
    },
    {  src: "./assets/attributes/a_img4.jpg",
       url: "https://unsplash.com/photos/gray-volkswagen-beetle-skTKCC0-Sy4" 
    },
    {  src: "./assets/attributes/a_img5.jpg",
       url: "https://unsplash.com/photos/gray-asphalt-road-near-brown-mountain-during-daytime-RrQ2C2QfIfM" 
    },
    {  src: "./assets/attributes/a_img6.jpg",
       url: "https://unsplash.com/photos/elephants-drinking-at-a-waterhole-PSuH54yhJr8" 
    },
    {  src: "./assets/attributes/a_img7.jpg",
       url: "https://unsplash.com/photos/white-swan-on-water-during-daytime-MDiwNU1pIdo" 
    },
    {  src: "./assets/attributes/a_img8.jpg",
       url: "https://unsplash.com/photos/a-living-room-filled-with-furniture-and-a-flat-screen-tv-shT_LaGUmYI" 
    },
    {  src: "./assets/attributes/a_img9.jpg",
       url: "https://unsplash.com/photos/yellow-labrador-retriever-biting-yellow-tulip-flower-Sg3XwuEpybU" 
    },
    
]

function ImageAttributions(props) {
  const { onClose, open} = props;

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);


  return (
    <Dialog  onClose={onClose} open={open} scroll="paper">
      <DialogTitle>Image Attributions</DialogTitle>
      <DialogContent dividers={true}>
      <DialogContentText tabIndex={-1}  ref={descriptionElementRef}>
        <List sx={{ pt: 0 }}>
            {allImage.map((item) => (
            <ListItem disableGutters key={item.src}  style={{padding:0}}>
                <img src={item.src} style={{maxWidth:128, padding: 8}}/>
                <Link target="_blank" rel="noopener" href={item.url} variant="body2">{item.url}</Link>
            </ListItem>
            ))}
        </List>
      </DialogContentText>
      </DialogContent>
      <DialogActions>
          <Button onClick={onClose}>Close</Button> 
      </DialogActions>
    </Dialog>
  );
}

export default ImageAttributions;