import React from 'react';

import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function Approach () {
      const imgStyle = {
        maxWidth: '100%',
        maxWeight: '100%',
        objectFit: 'contain'
    };
    return (
        <Container maxWidth="md" sx={{margin: 4}} >
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={4}>
          <Stack>
              <Typography variant="h6" component="h2" sx={{fontWeight: 600, textAlign: { xs: 'left', md: 'right' } }}>
               APPROACH
              </Typography>
          </Stack>
          <Stack>
              <Typography variant="body1" component="span" sx={{'textAlign': 'left' }}>
              We collect a counterfactual dataset consisting of photos of scenes before and after 
              removing an object, while keeping everything else fixed. We used this dataset to finetune 
              a diffusion model to remove an object and all its effects from the scene. For the task of 
              object insertion, we bootstrap bigger dataset by removing selected objects from a large 
              unsupervised image dataset, resulting in a vast, synthetic counterfactual dataset. 
              Training on this synthetic dataset and then fine tuning on a smaller, original, supervised 
              dataset yields a high quality object insertion model.
              </Typography>
          </Stack>
        </Stack>
        <Box style={{paddingTop: 16}}>
            <img src='./assets/overview.png' style={imgStyle}/>
          </Box>
        </Container>
    );
};
export default Approach;