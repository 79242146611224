import React from 'react';

import Container from '@mui/material/Container';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import HoverImage from './HoverImage';
import Typography from '@mui/material/Typography';
import ClickAlert from './ClickAlert';
import Grid from '@mui/material/Grid';

function ObjectInsert() {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <Container maxWidth="lg" sx={{ margin: 4 }} style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }}>
            <Container maxWidth="md" sx={{ margin: 4 }} >
                <Typography variant="h6" component="h2" sx={{ fontWeight: 600 }}>
                    OBJECT INSERTION
                </Typography>
                <Typography>
                    By training first on a large synthetic dataset created with the object removal model,
                    and then on a high-quality dataset, our object insertion model can accurately model
                    how an object affects its environment, achieving realistic results.
                </Typography>
                <ClickAlert />
            </Container>
            {
                isSmall ?
                <Grid container spacing={1} >
                    <Grid item xs={4} style={{margin: 'auto'}}>
                    <Typography style={{ fontWeight: 600, margin:8}}>Object</Typography>
                    </Grid>
                    <Grid item xs={8}>
                    <Typography style={{ fontWeight: 600, margin:8}}>Target Image</Typography>
                    </Grid>
                {[1, 2, 3, 4].map((item) => (
                    <>
                    <Grid item xs={4} style={{margin: 'auto'}}>
                    <img src={`./assets/object_insertion/references/img${item}.jpg`} width="100%"
                             style={{borderRadius: '12px'}}/>
                    </Grid>
                    <Grid item xs={8}>
                        <HoverImage srcTop={`./assets/object_insertion/target/img${item}.jpg`}
                            srcHover=""
                            srcClick={`./assets/object_insertion/result/img${item}.jpg`} />
                    </Grid>
                    </>
                ))}   
                 </Grid>
                 :
                 <table style={{width:"100%"}}>
                 <tr>
                     {/* <td><Typography style={{ fontWeight: 600, margin:8}}>Target<br />Image:</Typography></td> */}
                     <td>
                     <Grid container spacing={2}>
                     {[1, 2, 3, 4].map((item) => (
                         <Grid item xs={3}>
                             <HoverImage srcTop={`./assets/object_insertion/target/img${item}.jpg`}
                                 srcHover=""
                                 srcClick={`./assets/object_insertion/result/img${item}.jpg`} />
                         </Grid>
                     ))}   
                      </Grid>
                     </td>
                 </tr>
                 <tr>
                     {/* <td><Typography style={{ fontWeight: 600, margin:8}}>Reference Object:</Typography></td> */}
                     <td>
                     <Grid container spacing={2}>
                     {[1, 2, 3, 4].map((item) => (
                         <Grid item xs={3}>
                             <img src={`./assets/object_insertion/references/img${item}.jpg`} width="50%"
                             style={{borderRadius: '12px'}}/>
                         </Grid>
                     ))} 
                     </Grid>
                     </td>
                 </tr>
                 </table>
            }
        </Container>
    );
};
export default ObjectInsert;