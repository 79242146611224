import React from 'react';
import { TouchApp } from '@mui/icons-material';
import Alert from '@mui/material/Alert';

function ClickAlert (props) {
    return (
        <Alert icon={false}  severity="info" style={{
            marginTop: 16, 
            display: 'block',
            fontSize: '1rem',
            lineHeight: 1.5,
            fontWeight: 500,
            }} >
        <TouchApp style={{marginLeft:8, marginRight:8, verticalAlign: 'bottom',}}/>
        <span>Click on any image to see results</span>
        </Alert>
    );
};
export default ClickAlert;